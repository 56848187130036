<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    permission="system:font:list:button:edit"
    @show="show"
    :btnLoading="btnLoading"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot="">
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sequenceNo }}</info-item>
      <info-item :label-width="labelWidth" label="字体中文名">{{ curRow.displayName }}</info-item>
      <info-item :label-width="labelWidth" label="字体英文名">{{ curRow.englishName }}</info-item>
      <info-item :label-width="labelWidth" label="字体系列">{{ curRow.serialName }}</info-item>
      <info-item :label-width="labelWidth" label="抗锯齿">{{ getAntiAliasMethod(curRow.antiAliasMethod) }}</info-item>
      <info-item :label-width="labelWidth" label="字体用途">
        <span v-if="curRow.fontUsages">
          {{ curRow.fontUsages.join(',') }}
        </span>
      </info-item>
      <info-item :label-width="labelWidth" label="字体样式">{{ curRow.styleName }}</info-item>
      <info-item :label-width="labelWidth" label="适用语言">
        <span v-if="curRow.languages">
          {{ curRow.languages.join(',') }}
        </span>
      </info-item>
      <info-item :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departments" :key="one.departId">
          {{ one.departName }}
        </a-tag>
      </info-item>
      <info-item v-if="fontShow" :label-width="labelWidth" label="字体预览">
        <span :style="{ fontFamily: curRow.showFontFamily, fontSize: '42px' }">
          <span v-if="curRow.languages == '日语'">亜あアAa123</span>
          <span v-else>漫享创界comijoy2017</span>
        </span>
        <!--         <v-stage
          :config="{
            width: 200,
            height: 30
          }"
        >
          <v-layer>
            <v-text text="漫享创界comijoy2017" :fontFamily="fontFamily" :y="10" :fontSize="18"></v-text>
          </v-layer>
        </v-stage> -->
      </info-item>
    </div>

    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item>
          <!--           <a-upload
            name="file"
            accept=".ttf,.ttc,.otf,.fon"
            :headers="headers"
            action="/jeecg-boot/fonts/upload_font"
            @change="handleUploadChange"
          >
            <a-button> <a-icon type="upload" /> 上传字体 </a-button>
          </a-upload> -->
          <a-upload
            name="file"
            accept=".ttf,.ttc,.otf,.fon"
            :show-upload-list="false"
            :action="ossUploadUrl"
            :data="ossUploadData"
            :beforeUpload="beforeUpload"
            @change="
              info => {
                handleChangeUpload(info)
              }
            "
          >
            <a-button :loading="btnLoading"> <a-icon type="upload" /> 上传字体 </a-button>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input v-model="formData.sequenceNo" placeholder="请输入序号"></a-input>
        </a-form-model-item>

        <a-form-model-item label="字体中文名" prop="displayName">
          <a-input v-model="formData.displayName" placeholder="请输入字体中文名称"></a-input>
        </a-form-model-item>

        <a-form-model-item label="字体英文名" prop="englishName">
          <a-input v-model="formData.englishName" placeholder="请输入字体英文名名称" :disabled="true"></a-input>
        </a-form-model-item>

        <a-form-model-item label="字体系列" prop="serialName">
          <a-input v-model="formData.serialName" placeholder="请输入字体系列名称"></a-input>
        </a-form-model-item>

        <a-form-model-item label="抗锯齿">
          <a-select v-model="formData.antiAliasMethod" placeholder="请选择">
            <a-select-option v-for="item in antiAliasMethodConfig" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="字体用途">
          <a-select v-model="formData.fontUsages" placeholder="请选择字体用途" mode="multiple">
            <a-select-option v-for="item in textStyleTypeList" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="字体样式" prop="styleName">
          <a-select v-model="formData.styleName">
            <a-select-option key="Regular" value="Regular">Regular</a-select-option>
            <a-select-option key="Bold" value="Bold">Bold</a-select-option>
            <a-select-option key="Italic" value="Italic">Italic</a-select-option>
          </a-select>
          <!-- <a-input v-model="formData.styleName" placeholder="请输入字体样式名称" :disabled="false"></a-input> -->
        </a-form-model-item>

        <a-form-model-item label="适用语言">
          <a-select v-model="formData.languages" placeholder="请选择适用语言" mode="multiple">
            <a-select-option v-for="item in languageConfig" :key="item.id">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="适用部门" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDeparts"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>

        <a-form-model-item label="字体预览">
          <span :style="{ fontFamily: formData.showFontFamily, fontSize: '42px' }">
            <span v-if="formData.languages == '日语'">亜あアAa123</span>
            <span v-else>漫享创界comijoy2017</span>
          </span>
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { postAction } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { batchStyleInsertHeader } from '@/utils/util'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
const languageConfig = [
  {
    value: '中文简体',
    id: '中文简体'
  },
  {
    value: '中文繁体',
    id: '中文繁体'
  },
  {
    value: '日语',
    id: '日语'
  },
  {
    value: '韩语',
    id: '韩语'
  },
  {
    value: '英语',
    id: '英语'
  }
]
const fontUsages = [
  {
    value: '中文简体',
    id: '中文简体'
  }
]

const antiAliasMethodConfig = [
  {
    value: 'antiAliasStrong',
    label: '浑厚'
  },
  {
    value: 'antiAliasSharp',
    label: '锐利'
  },
  {
    value: 'antiAliasCrisp',
    label: '犀利'
  },
  {
    value: 'antiAliasSmooth',
    label: '平滑'
  }
]

const defaultFormData = {
  displayName: '',
  englishName: '',
  serialName: '',
  styleName: '',
  fontUsages: '',
  languages: [],
  departments: []
}
const defaultSelectedDeparts = []

export default {
  mixins: [DrawerMixins, UploadFileToOssMixins],
  data() {
    return {
      headers: {
        'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)
      },
      selectedDeparts: [],
      languageConfig,
      antiAliasMethodConfig,
      rules: {
        displayName: [{ required: true, message: '请输入字体中文名称', trigger: 'blur' }]
      },
      url: {
        edit: '/fonts/edit',
        add: '/fonts/upload'
      },
      labelWidth: '90',
      layout: {
        labelCol: {
          style: 'width: 90px',
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      },
      uploadPath: 'glxt/font/',
      fontFamily: '',
      fontShow: false
    }
  },
  methods: {
    initForm() {
      this.formData = {
        ...defaultFormData
      }
      this.selectedDeparts = [...defaultSelectedDeparts]
    },

    show() {
      this.fontShow = false
      this.$nextTick(() => {
        this.fontFamily = this.curRow.fontFamily
        this.fontShow = true
      })
      console.log('显示...')
      this.getDepartList()
      this.getTextStyleType()

      if (this.isEdit) {
        this.formData = this.curRow
        // console.log(this.formData, this.curRow,"this.formData = this.curRow")
        const { departments } = this.formData
        this.selectedDeparts = departments.map(item => item.departId)
      }
    },

    startEdit() {
      if (this.isEdit) {
        this.formData = this.curRow
        const { departments } = this.formData
        this.selectedDeparts = departments.map(item => item.departId)
      }
      if (!this.formData.fontUsages) {
        this.formData.fontUsages = []
      }
    },

    setFormData() {},
    beforeUpload(file) {
      console.log('上传之前，检查文件类型')
      this.btnLoading = true
      return this.ossPolicy(file.name)
    },
    async handleChangeUpload(info) {
      if (info.file.status === 'done') {
        this.btnLoading = true
        let params = this.getSaveFileParams(info.file)
        let formData = new FormData()
        formData.append('file', info.file.originFileObj)
        formData.append('fileUrl', params.filePath)
        const res = await postAction('/fonts/upload_font', formData)
        this.btnLoading = false
        if (res.code == 200) {
          if (!this.formData.id) {
            this.formData = {
              ...this.formData,
              ...res.data,
              languages: [],
              fontUsages: [],
              departments: this.selectedDeparts
            }
          } else {
            this.formData.englishName = res.data.englishName
            this.formData.fontFamily = res.data.fontFamily
            this.formData.postScriptName = res.data.postScriptName
            this.formData.fontFilePath = res.data.fontFilePath
            this.formData.cssFilePath = res.data.cssFilePath
          }
          this.formData.fontUrl = res.data.fontUrl
          this.formData.fontFilePath = res.data.fontFilePath
          batchStyleInsertHeader([this.formData])
          this.$message.success(`${info.file.name} 上传成功`)
        } else {
          this.$message.error(`处理字体失败,${res.msg}`)
        }
      }
    },
    handleUploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        if (info.file.response.success) {
          if (!this.formData.id) {
            this.formData = {
              ...this.formData,
              ...info.file.response.data,
              languages: [],
              fontUsages: [],
              departments: this.selectedDeparts
            }
          } else {
            this.formData.englishName = info.file.response.data.englishName
            this.formData.fontFamily = info.file.response.data.fontFamily
            this.formData.postScriptName = info.file.response.data.postScriptName
            this.formData.fontFilePath = info.file.response.data.fontFilePath
            this.formData.cssFilePath = info.file.response.data.cssFilePath
          }
          batchStyleInsertHeader([this.formData])
          this.$message.success(`${info.file.name} file uploaded successfully`)
        } else {
          this.$message.error(`处理字体失败,${info.file.response.message}`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },

    getAntiAliasMethod(method) {
      let config = antiAliasMethodConfig
      for (let idx = 0; idx < config.length; idx++) {
        if (config[idx].value == method) {
          return config[idx].label
        }
      }
      return '锐利'
    }
  },
  computed: {},
  created() {}
}
</script>

<style lang="stylus">
.upload-btn {
  margin-bottom: 20px;
}
</style>
