var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:font:list:button:edit",
        btnLoading: _vm.btnLoading,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sequenceNo))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "字体中文名" } },
            [_vm._v(_vm._s(_vm.curRow.displayName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "字体英文名" } },
            [_vm._v(_vm._s(_vm.curRow.englishName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "字体系列" } },
            [_vm._v(_vm._s(_vm.curRow.serialName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "抗锯齿" } },
            [_vm._v(_vm._s(_vm.getAntiAliasMethod(_vm.curRow.antiAliasMethod)))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "字体用途" } },
            [
              _vm.curRow.fontUsages
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.curRow.fontUsages.join(",")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "字体样式" } },
            [_vm._v(_vm._s(_vm.curRow.styleName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用语言" } },
            [
              _vm.curRow.languages
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.curRow.languages.join(",")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用部门" } },
            _vm._l(_vm.curRow.departments, function (one) {
              return _c("a-tag", { key: one.departId }, [
                _vm._v("\n        " + _vm._s(one.departName) + "\n      "),
              ])
            }),
            1
          ),
          _vm.fontShow
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "字体预览" } },
                [
                  _c(
                    "span",
                    {
                      style: {
                        fontFamily: _vm.curRow.showFontFamily,
                        fontSize: "42px",
                      },
                    },
                    [
                      _vm.curRow.languages == "日语"
                        ? _c("span", [_vm._v("亜あアAa123")])
                        : _c("span", [_vm._v("漫享创界comijoy2017")]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        name: "file",
                        accept: ".ttf,.ttc,.otf,.fon",
                        "show-upload-list": false,
                        action: _vm.ossUploadUrl,
                        data: _vm.ossUploadData,
                        beforeUpload: _vm.beforeUpload,
                      },
                      on: {
                        change: (info) => {
                          _vm.handleChangeUpload(info)
                        },
                      },
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { loading: _vm.btnLoading } },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" 上传字体 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "字体中文名", prop: "displayName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入字体中文名称" },
                    model: {
                      value: _vm.formData.displayName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "displayName", $$v)
                      },
                      expression: "formData.displayName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "字体英文名", prop: "englishName" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入字体英文名名称",
                      disabled: true,
                    },
                    model: {
                      value: _vm.formData.englishName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "englishName", $$v)
                      },
                      expression: "formData.englishName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "字体系列", prop: "serialName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入字体系列名称" },
                    model: {
                      value: _vm.formData.serialName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "serialName", $$v)
                      },
                      expression: "formData.serialName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "抗锯齿" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.antiAliasMethod,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "antiAliasMethod", $$v)
                        },
                        expression: "formData.antiAliasMethod",
                      },
                    },
                    _vm._l(_vm.antiAliasMethodConfig, function (item) {
                      return _c("a-select-option", { key: item.value }, [
                        _vm._v(
                          "\n            " + _vm._s(item.label) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "字体用途" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择字体用途",
                        mode: "multiple",
                      },
                      model: {
                        value: _vm.formData.fontUsages,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "fontUsages", $$v)
                        },
                        expression: "formData.fontUsages",
                      },
                    },
                    _vm._l(_vm.textStyleTypeList, function (item) {
                      return _c("a-select-option", { key: item.value }, [
                        _vm._v(
                          "\n            " + _vm._s(item.label) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "字体样式", prop: "styleName" } },
                [
                  _c(
                    "a-select",
                    {
                      model: {
                        value: _vm.formData.styleName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "styleName", $$v)
                        },
                        expression: "formData.styleName",
                      },
                    },
                    [
                      _c(
                        "a-select-option",
                        { key: "Regular", attrs: { value: "Regular" } },
                        [_vm._v("Regular")]
                      ),
                      _c(
                        "a-select-option",
                        { key: "Bold", attrs: { value: "Bold" } },
                        [_vm._v("Bold")]
                      ),
                      _c(
                        "a-select-option",
                        { key: "Italic", attrs: { value: "Italic" } },
                        [_vm._v("Italic")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "适用语言" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择适用语言",
                        mode: "multiple",
                      },
                      model: {
                        value: _vm.formData.languages,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "languages", $$v)
                        },
                        expression: "formData.languages",
                      },
                    },
                    _vm._l(_vm.languageConfig, function (item) {
                      return _c("a-select-option", { key: item.id }, [
                        _vm._v(
                          "\n            " + _vm._s(item.value) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "适用部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                      placeholder: "请选择适用部门",
                    },
                    on: { change: _vm.changeDepartList },
                    model: {
                      value: _vm.selectedDeparts,
                      callback: function ($$v) {
                        _vm.selectedDeparts = $$v
                      },
                      expression: "selectedDeparts",
                    },
                  }),
                ],
                1
              ),
              _c("a-form-model-item", { attrs: { label: "字体预览" } }, [
                _c(
                  "span",
                  {
                    style: {
                      fontFamily: _vm.formData.showFontFamily,
                      fontSize: "42px",
                    },
                  },
                  [
                    _vm.formData.languages == "日语"
                      ? _c("span", [_vm._v("亜あアAa123")])
                      : _c("span", [_vm._v("漫享创界comijoy2017")]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }